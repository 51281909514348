import { useState } from "react"
import { Timetable } from "./timetable"
import { getTimetable } from "../api/api"
import { Oval } from "react-loader-spinner"


export const Trainer = ({ trainer, type }) => {
    const today = new Date()
    let maxDate = new Date()

    
    maxDate.setMonth(maxDate.getMonth() + 1)
    const formatDate = (date) => {
        
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        
        let yy = date.getFullYear();
        
        return yy + '-' + mm + '-' + dd;
    }
    
    const [date, setDate] = useState(formatDate(today))

    // const [schedule, setSchedule] = useState(trainer?.timetable)
    // console.log(schedule)
    const [schedule, setSchedule] = useState(trainer?.timetable?.filter(el => el.kindOfSport === type))
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const dateChange = async (e) => {
        setSchedule([])
        setError('')
        setLoading(false)
        const date = e.target.value.split('-').reverse().join('.')
        setDate(e.target.value)
        const data = await getTimetable(trainer.trainer_ID, date)
        setSchedule(data.timetable?.filter(el => el.kindOfSport === type))
        setError(data.errorMessage)
        setLoading(true)

    }
    return (
        <div className='col-md-4'>
            <div className='icon'>
                <img
                    src={`https://instructors.yes35.ru/img${trainer?.trainer_Photo}`}
                    className='img-responsive'
                />
            </div>
            <div className='service-desc'>
                <h3>{trainer.trainer_FIO}</h3>
                <p dangerouslySetInnerHTML={{__html: trainer?.description}}></p>

                <input type="date"
                    style={{ maxWidth: '130px' }}
                    value={date}
                    min={formatDate(today)}
                    max={formatDate(maxDate)}
                    onChange={(e) => dateChange(e)}
                />
                {
                    loading ?
                        <>
                            {
                                !error ?
                                    <Timetable schedule={schedule} />
                                    :
                                    <p>{error}</p>
                            }
                        </>
                        :
                        <Oval
                            visible={true}
                            height="80"
                            width="80"
                            color="#ff7f00"
                            secondaryColor='#ff7f00'
                            ariaLabel="loading"
                        />
                }
            </div>
        </div>
    )
}